<template>
    <div>
        <div style="background-color: #f5f2f0">
            <div v-pre>
                <div id="pretix-widget"></div>
            </div>
        </div>
        <noscript>
            <div class="pretix-widget">
                <div class="pretix-widget-info-message">
                    JavaScript ist in deinem Browser deaktiviert. Um unseren Ticket-Shop ohne JavaScript aufzurufen,
                    klicke
                    bitte <a target="_blank" rel="noopener" href="https://tickets.forceo.de/forceo/business-brunch/">hier</a>.
                </div>
            </div>
        </noscript>
    </div>
</template>

<script>

    export default {
        name: "PretixCalendar",
        props:{
            foundercockpit: Boolean,
            event: String,
            subevent: Number
        },
        mounted() {
            let Script = document.createElement("script");
            Script.setAttribute("id", "pretix-script");
            Script.setAttribute("src", "https://tickets.forceo.de/widget/v1.de-informal.js");
            document.head.appendChild(Script);

            let widget_div = document.getElementById('pretix-widget');
            let widget = document.createElement("pretix-widget");
            widget.setAttribute('event', this.event);
            if(this.subevent !== undefined) {
                widget.setAttribute('subevent', this.subevent);
            }
            widget_div.appendChild(widget)
        },
        unmounted() {
            let widget_div = document.getElementById('pretix-widget');
            widget_div.remove();


            let widgetElements = document.getElementsByTagName('pretix-widget');
            for (let i=0; i < widgetElements.length; i++){
                widgetElements[i].remove()
            }
        }
    }
</script>

<style>

    .pretix-widget-event-calendar-next-month {
        color: #585e5f !important;
        font-weight: bold;
    }
    .pretix-widget-event-list-back a {
        color: #585e5f !important;
        font-weight: bold;
    }


    .pretix-widget-event-calendar-previous-month {
        color: #585e5f !important;
        font-weight: bold;
    }
</style>