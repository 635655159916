<template>
    <v-app>
        <v-main class="founderSecondary fill-height">
            <section style="height: 100%">
                <v-container class="fill-height">
                    <v-row align="center" justify="center">
                        <v-col style="max-width: 1000px">
                            <h1 class="forceo-thick-title-upper white--text">Von diesen Vorteilen profitieren unsere Teilnehmer</h1>
                            <section class="mt-8 mb-10 mx-md-10 white--text" >
                                <ul>
                                    <li>Praxisbasierter Erfahrungsaustausch auf der Salonleiterebene</li>
                                    <li>Einblick in die Produktstrategie zur Steigerung der Salonrentabilität</li>
                                    <li>Neue Kontakte aus der Beauty Business Branche</li>
                                </ul>
                            </section>
                            <div class="text-center">
                                <v-btn @click="overlay = !overlay" color="orange" class="forceo-button" dark>Anmeldung zum Business Brunch</v-btn>
                                <p class="white--text my-2 forceo-smalltext">*Die Teilnehmeranzahl ist auf 20 Personen begrenzt.</p>
                            </div>
                        </v-col>

                    </v-row>

                </v-container>
            </section>
            <section>
                <v-dialog v-model="overlay" max-width="1600px">
                    <PretixCalendar :foundercockpit="true"></PretixCalendar>

                </v-dialog>
            </section>
        </v-main>
    </v-app>
</template>

<script>
    import './assets/css/main.css'
    import PretixCalendar from "@/components/PretixCalendar";
    import axios from 'axios';

    export default {
        name: 'FounderCockpit',

        components: {
            PretixCalendar
        },

        data: () => ({
            overlay: true,
            zIndex: 0,
            subevents: {}
        }),
        mounted() {
            axios.get('https://tickets.forceo.de/api/v1/organizers/forceo/events/business-brunch/subevents/')
                .then(res => this.subevents = res.data)
        }
    };
</script>

<style>
    .lottie{
        height: 200px;
        margin: auto;
    }

    ul {
        list-style-type: none;
    }

    li {
        font-size: 1.2rem;
        line-height: 2;
    }

</style>