<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
    import './assets/css/main.css'

    export default {
        name: 'App',

        components: {
        },
    };
</script>

<style>
</style>