import Vue from "vue";
import vuetify from './plugins/vuetify'
import LottieAnimation from 'lottie-web-vue'
import FounderCockpit from "@/FounderCockpit";
import VueRouter from 'vue-router'
import Forceo from "@/Forceo";
import App from "@/App";

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.use(VueRouter);

const routes = [
    { path: '/', component: Forceo },
    { path: '/foundercockpit', component: FounderCockpit },
]

const router = new VueRouter({
    routes // short for `routes: routes`
})

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
