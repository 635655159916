<template>
    <v-app>
        <v-main class="secondary fill-height">
            <section class="text-center">
                <div class="py-9 pb-1 white hidden-xs-only">
                    <h1 class="forceo-thick-title-upper">Herzlich Willkommen zum Beauty Business Brunch!</h1>
                </div>
                <div class="py-2 white hidden-sm-and-up">
                    <h1 class="forceo-thick-title-upper">Herzlich Willkommen zum Beauty Business Brunch!</h1>
                </div>
                <div class="primary   ">
                    <h3 class="forceo-thick-title">Deine Community für den professionellen Austausch</h3>
                </div>

                <div v-pre>
                    <pretix-widget v-pre event="https://tickets.forceo.de/forceo/business-brunch/"></pretix-widget>
                </div>
            </section>
            <section class="pt-12">
                <v-container class="fill-height">
                    <v-row align="center" justify="center">
                        <v-col>
                            <h1 class="forceo-thick-title white--text hidden-sm-and-up" >Darauf darfst Du gespannt sein!</h1>
                            <h1 class="forceo-thick-title white--text hidden-xs-only" style="font-size: 1.6rem">Darauf darfst Du gespannt sein!</h1>
                            <section class="my-10 mx-md-10 white--text">
                                <v-row>
                                    <v-col>

                                    </v-col>
                                    <v-col></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex" @mouseenter="resetAnimation($refs.communication)">
                                        <v-card hover class="d-flex flex-column">
                                            <div style="width: 100%">
                                                <div >
                                                    <lottie-animation
                                                            class="lottie"
                                                            ref="communication"
                                                            :animationData="require('@/assets/animations/communication.json')"
                                                            style="transform: translate(0,25px) scale(1.3)"
                                                    />
                                                </div>
                                                <h1 class="forceo-thick-title-upper">Austausch</h1>
                                            </div>
                                            <v-card-text class="feature-box-text-bottom">Unternehmerischer Austausch unter Gleichgesinnten</v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col class="d-flex" @mouseenter="resetAnimation($refs.efficiency)">
                                        <v-card hover class="d-flex flex-column">
                                            <div style="width: 100%">
                                                <lottie-animation
                                                        class="lottie"
                                                        ref="efficiency"
                                                        :animationData="require('@/assets/animations/efficiency.json')"
                                                        style="transform: translate(0,10px) scale(0.9)"
                                                />
                                                <h1 class="forceo-thick-title-upper">Effektivität</h1>
                                            </div>
                                            <v-card-text class="feature-box-text-bottom">Fachlicher Input für mehr Effektivität in Deinem Salon</v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col class="d-flex" @mouseenter="resetAnimation($refs.money)">
                                        <v-card hover class="d-flex flex-column">
                                            <div style="width: 100%">
                                                <lottie-animation
                                                        class="lottie"

                                                        ref="money"
                                                        :animationData="require('@/assets/animations/lf30_editor_df0j4pat.json')"
                                                        style="transform: translate(0,10px) scale(1)"
                                                />
                                                <h1 class="forceo-thick-title-upper hidden-lg-and-up">Profit<br>Steigerung</h1>
                                                <h1 class="forceo-thick-title-upper hidden-md-and-down">Profitsteigerung</h1>
                                            </div>
                                            <v-card-text class="feature-box-text-bottom">Inspiration zur Profitsteigerung durch Produktverkauf</v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col class="d-flex">
                                        <v-card hover class="d-flex flex-column">
                                            <div style="width: 100%">
                                                <lottie-animation
                                                        class="lottie"
                                                        ref="buffet"
                                                        :loop="true"
                                                        :animationData="require('@/assets/animations/99705-food-is-ready.json')"
                                                        style="transform: translate(0,-5px) scale(1.2)"
                                                />
                                                <h1 class="forceo-thick-title-upper">Buffet</h1>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-card-text class="feature-box-text-bottom">Großes Brunch Buffet im gehobenen 4 Sterne Ambiente</v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </section>
                            <div class="text-center mb-12">
                                <v-btn wi @click="this.openDialog" color="orange" class="forceo-button hidden-sm-and-up" dark>JETZT ANMELDEN</v-btn>
                                <v-btn wi @click="this.openDialog" color="orange" class="forceo-button big-forceo-button hidden-xs-only" dark>JETZT ANMELDEN</v-btn>
                                <p class="white--text my-2 forceo-smalltext">*Die Teilnehmeranzahl ist auf 15 Personen begrenzt.</p>
                            </div>
                        </v-col>

                    </v-row>

                </v-container>
            </section>
            <section>
                <v-dialog v-model="overlay" :max-width="step === 1 ? '600px': '1600px'"  @click:outside="onDialogClose" >
                    <div >

                        <section v-if="step === 1">
                            <v-card class="pa-12 dialog-bg" max-width="600px">
                                            <div class="mb-6 text-center">
                                                <h2 class="forceo-thick-title" ><v-icon large class="mr-3" color="secondary">mdi-map-marker-multiple</v-icon>Wähle einen Veranstaltungsort</h2>
                                            </div>
                                            <v-row justify="center">
                                                <v-col  v-for="place in places" v-bind:key="place.city" style="max-width: 300px">
                                                    <v-card @click="selectPlace(place)" hover>
                                                        <v-card-title>
                                                            <v-icon>mdi-map-marker</v-icon>
                                                            {{place.city}}</v-card-title>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                            </v-card>
                        </section>

                        <section v-if="step === 2" class="dialog-bg" style="min-height: 645px">
                            <v-card-title>
                                <h2 class="forceo-thick-title hidden-md-and-up mb-2">
                                    <v-icon large class="mr-3" color="secondary">mdi-calendar</v-icon>
                                    Anmeldung
                                </h2>
                                <h2 class="forceo-thick-title hidden-sm-and-down" >
                                    <v-icon large class="mr-3" color="secondary">mdi-calendar</v-icon>
                                    Anmeldung zum Beauty Business Brunch
                                </h2>
                                <v-spacer class=""></v-spacer>
                                <div class="mx-6">
                                    <h3 class="secondary--text" color="secondary"><v-icon color="secondary" large>mdi-map-marker</v-icon>{{selectedPlace.city}}</h3>
                                </div>
                                <v-btn color="secondary" @click="step=1">
                                    <v-icon left>
                                        mdi-pencil
                                    </v-icon>
                                    Ort ändern</v-btn>
                            </v-card-title>
                            <v-card-text>
                                <PretixCalendar id="pretix-widget-wrapper" v-if="selectedPlace" :event="selectedPlace.url"></PretixCalendar>

                            </v-card-text>
                        </section>
                    </div>

                </v-dialog>
            </section>
            <footer style="position: absolute; bottom: 0px; text-align: center; width: 100vw">
                <a class="mx-3" href="https://tickets.forceo.de/terms/agb.html">Allgemeine Geschäftsbedingungen</a>
                <a class="mx-3" href="https://tickets.forceo.de/terms/privacy.html">Datenschutzerklärung</a>
                <a class="mx-3" href="https://www.forceo.de/impressum.php">Impressum</a>
            </footer>
        </v-main>
    </v-app>
</template>

<script>
    import './assets/css/main.css'
    import PretixCalendar from "@/components/PretixCalendar";
    import LottieAnimation from 'lottie-web-vue'
    import axios from 'axios';

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Forceo',

        components: {
            LottieAnimation,
            PretixCalendar
        },

        data: () => ({
            overlay: false,
            zIndex: 0,
            subevents: [],
            subevent: undefined,
            openCount: 0,
            step: 1,
            places: [
                {"city": "Berlin", "short": "business-brunch-berlin", "url": "https://tickets.forceo.de/forceo/business-brunch-berlin/", events: []},
                {"city": "Bielefeld", "short": "business-brunch-bielefeld","url": "https://tickets.forceo.de/forceo/business-brunch-bielefeld/", events: []},
            ],
            selectedPlace: null,
            apiConfig: {
                headers: {
                    'Authorization': 'Token 06xdjw91wsrxpe9wdc2a9ea43nnk7mbmadjlg4ythr235jfh4dozdqg35fh2rjl2'
                }
            }

        }),
        mounted() {
        },

        methods: {
            resetAnimation: function(reference){
                reference.stop()
                reference.play()
            },
            communicationLottieFinished: function () {
                this.$refs.communication.setDirection(1);
            },
            selectPlace(place){
              this.step = 2;
              this.selectedPlace = place
            },
            cleanPretix(){
                let script_el = document.getElementById('pretix-script');
                if(script_el) script_el.remove();

                let widget_div = document.getElementById('pretix-widget');
                if (widget_div) widget_div.remove();

                let widgetElements = document.getElementsByTagName('pretix-widget');
                for (let i=0; i < widgetElements.length; i++){
                    widgetElements[i].remove()
                }

                let widgetOverlayElements = document.getElementsByClassName('pretix-widget-overlay');
                for (let i=0; i < widgetOverlayElements.length; i++){
                    widgetOverlayElements[i].remove()
                }
            },
            onDialogClose(){
              this.step = 1;
              this.selectedPlace = null;
              this.cleanPretix();
            },
            openDialog(){
                this.overlay = true;
                this.cleanPretix();
            },
            getEvents(url, events=undefined){
                axios.get(url, this.apiConfig)
                    .then(res => {
                        if(!events) {
                            events = res.data.results;
                        } else {
                            events.push(...res.data.results);
                        }

                        if (res.data.next !== null){
                            return this.getEvents(res.data.next, events)
                        }
                        console.log(events)
                        return events;
                    })
            }

        }
    };
</script>

<style>
    @keyframes dialog-bg-animation {
        from {
            backdrop-filter: blur(0px);
        }
        to {
            backdrop-filter: blur(10px);
        }
    }

    .feature-box-text-bottom {

        text-align: center;
        text-justify: auto;
    }

    .dialog-bg{
        background-color: rgba(220,176,153,0.9)!important;
        backdrop-filter: blur(10px);
        animation-name: dialog-bg-animation;
        animation-duration: 1.5s;
    }

    .lottie{
        height: 200px;
        margin: auto;
    }

    .big-forceo-button{
        height: 50px!important;
        font-size: 1.3rem!important;
    }

    .v-sheet.v-card--hover {
        cursor: default;
    }

    .break:before {
        content:"\A";
        white-space:pre;
    }

</style>